import type {NextPage} from 'next'
import HtmlHead from "../src/components/headerAndFooter/HtmlHead";
import React from 'react';
import PropertyLines from "../src/components/propertyLines/PropertyLines";
import HowIsItWorking from "../src/components/howIsItWorking/HowIsItWorking";
import LandingLayout from "../src/layouts/LandingLayout";
import BlogPreview from "../src/components/blog/BlogPreview";
import data from '../src/constants/data/LandingPageData.json';
import SubHeroAreaText from "../src/components/subHeroAreaText/SubHeroAreaText";
import SubHeroSuccessStory from "../src/components/subHeroSuccessStory/SubHeroSuccessStory";
import DesktopHeroArea from "../src/components/desktopHeroArea/DesktopHeroArea";
import HowIsYourStory from "../src/components/generalButtons/HowIsYourStoryButton";
import {useWindowSize} from "../src/utils/Hooks";
import MobileKayitForm from "../src/components/mobileKayitForm/MobileKayitForm";
import WaveLayout from "../src/layouts/WaweLayout";
import styled from "styled-components";
import {deviceMax} from "../styles/device";
import UserReviewsList from "../src/components/userReview/UserReviewList";

const Home: NextPage = () => {

    const windowSize = useWindowSize();

    return (
        <React.Fragment>
            <HtmlHead
                pageTitle={'Pembe Panjur | Türkiye\'nin En Ciddi Arkadaşlık & Evlilik Sitesi'}
                description={'Pembe Panjur, ciddi ilişki arayanları bilimsel kişilik testi ve sosyo-ekonomik yaşam analizi ile eşleştiren tanışma sitesi olup, aynı zamanda flört ve evlilik sitesidir.'}
                canonical={true}
            />

            {(windowSize.width < 768)?(
                <WaveLayout backgroundColor={'unset'} hamburgerMenuType={'black'} hamburgerMenu={false} loginButtonMenu={true} registerButton={false} waveComponent={false} bgColor={true}
                            extraChildren={
                                <React.Fragment>
                                    <HowIsItWorking/>
                                    <PropertyLines layoutPage={'landing'} propertyData={data.PropertyLinesLandingPageData}/>
                                    <BlogPreview/>
                                </React.Fragment>
                            }
                >
                    <React.Fragment>
                        <KayitWrapper>
                            <MobileKayitForm/>
                        </KayitWrapper>
                        <SubHeroAreaText/>
                        <SubHeroSuccessStory/>
                        <HowIsYourStory/>
                    </React.Fragment>
                </WaveLayout>
            ):(
                <LandingLayout
                    bodyBackgroundColor={false}
                    heroArea={
                        <React.Fragment>
                            <DesktopHeroArea/>
                        </React.Fragment>
                    }
                    heroAreaBackgroundImage={'/next/assets/images/mobile-landing-hero-background.jpg'}
                    heroAreaBackgroundImageDesktop={'/next/assets/images/desktop-landing-hero-background.webp'}
                    subHeroArea={
                        <React.Fragment>
                            <SubHeroAreaText/>
                            <SubHeroSuccessStory/>
                            <HowIsYourStory/>
                        </React.Fragment>
                    }
                >
                    <HowIsItWorking/>
                    <PropertyLines layoutPage={'landing'} propertyData={data.PropertyLinesLandingPageData} backgroundColor={'unset'}/>
                    <UserReviewsList />
                    <BlogPreview/>
                </LandingLayout>
            )}

        </React.Fragment>
    )
}


const KayitWrapper = styled.div`
    position: relative;
    background: #0000000D;
    width: calc(100% + 70px);
    margin-left: -35px;

    @media ${deviceMax.tablet} {
        padding: 0 35px 20px;
    }
`;

import { checkAuthAndRedirect } from '../src/utils/authHelper';
export const getServerSideProps = async (context: any) => {
    return await checkAuthAndRedirect(context);
};

export default Home

// export {genericGetServerSideProps as getServerSideProps} from '../src/utils/GenericServerSideProps';
